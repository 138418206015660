import React from 'react';
import classNames from 'classnames';
import { trackActivity } from '@app/src/services/analyticsService';
import '@app/src/Components/TaxFlow/Question/TaxFlowBillElement.scss';

const TaxFlowBillRow = ({
  label,
  amount,
  color = true,
  positiveSign = true,
  negativeSign = false,
  decimalPlaces = 2
}) => {
  const billDue = amount >= 0;

  label = typeof label === 'function' ? label(billDue) : label;

  if (amount === null) {
    return null;
  }

  const formatCurrency = (value) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      signDisplay: 'never',
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces
    });

    if (formatter.format(value) === '$NaN') {
      trackActivity('taxflow bill row invalid amount', {
        amount,
        label
      });
    }

    return formatter.format(value);
  };

  return (
    <div className='taxflow-bill-row'>
      <div className='taxflow-bill-row-label'>{label}</div>
      <div
        className={classNames('taxflow-bill-row-value', {
          refund: color === 'refund' || (color === true && !billDue),
          bill: color === 'bill' || (color === true && !!billDue)
        })}
      >
        {billDue
          ? (negativeSign ? '- ' : '') + formatCurrency(amount)
          : (positiveSign ? '+ ' : '') + formatCurrency(amount)}
      </div>
    </div>
  );
};

export default TaxFlowBillRow;
